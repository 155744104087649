import { LoadingCircle } from "@givesafe/design-system-react";
import React, { Suspense, useEffect, useState } from "react";

import { ReactQueryDevtools } from "react-query/devtools";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { useLocalStorage } from "react-use";

// import Landing from "./Landing";
import Login from "./Login";
import Registration from "./Registration";
import useValidateToken from "../hooks/api/useValidateToken";
import { setSamaritanUrl } from "../httpClient";

const Me = React.lazy(() => import("./Me"));

const Fallback = (
  <div style={{ height: "100vh" }}>
    <LoadingCircle />
  </div>
);

function App() {
  // const history = useHistory();
  const user = useSelector((s) => (s.user && s.user.data) || null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { validateToken, isLoading } = useValidateToken(setErrorMessage);
  const [, setCity] = useLocalStorage("samaritan::city", {});
  const urlParams = new URLSearchParams(window.location?.search || "");
  const token = urlParams.get("token");
  const cityParam = urlParams.get("city");
  const directToQol = urlParams.get("directToQol");
  const [shouldRedirectToQol, setShouldRedirectToQol] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      if ( token && cityParam ) {
        const [subdomainName, cityName] = cityParam.split("/");

        if (directToQol) setShouldRedirectToQol(true);
        if (subdomainName && cityName) {
          const cityObject = {
            subdomain_name: subdomainName,
            name: cityName,
          };
          setCity(cityObject);
          setSamaritanUrl();
          try {
            await validateToken(token);
          } catch ( error ) {
            console.error("Error:", error);
          }
        } else {
          setErrorMessage("Broken link");
        }
      }
      return null;

    };

    if ( token && cityParam ) {
      fetchData();
    }
  }, [token, cityParam]); // eslint-disable-line react-hooks/exhaustive-deps

  if ( isLoading ) {
    return ( Fallback )
  }

  return (
    <>
      <Switch>
        {user ? (
          <Redirect path="/login" to="/me" />
        ) : (
          <Route path="/login" exact render={() => <Login errorMessage={errorMessage} />} />
        )}

        {user ? (
          <Route
            path="/me"
            render={() => (
              <Suspense fallback={Fallback}>
                <Me shouldRedirectToQol={shouldRedirectToQol} />
              </Suspense>
            )}            
          />
        
        ) : (
          <Redirect path="/me" to="/" />
        )}


        <Route path="/register" exact component={Registration} />

        <Route path="/" exact>
          {(() => {
            if (user) {
              return <Redirect to="/me" />;
            }
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { errorMessage }
                }}
              />
            );
          })()}
        </Route>


      </Switch>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}

export default App;
